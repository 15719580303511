import React from 'react';
import {Carousel} from "react-materialize";

class Objectives extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: null
        };
    }

    componentDidMount() {
        const interval = setInterval(function () {
            const carousel = document.querySelector('.carousel');
            if (carousel && carousel.M_Carousel) {
                carousel.M_Carousel.next()
            }
        }, 10000);
        this.setState(() => ({interval}));
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() {
        return (
            <div>
                <h3 className='About__title'>GopherTuts goals & objectives</h3>
                <Carousel
                    options={{fullWidth: true, indicators: true, autoplay: true, duration: 200}}
                    className="white-text center About__objectives"
                >
                    <div className="About__objectives__panel About__objectives__panel--learn">
                        <h2 className='About__objectives__panel__title'>Learn</h2>
                        <p className='About__objectives__panel__body'>
                            Learn with in depth explanations, examples and interesting diagrams.
                            Learn by real world examples, from simple to more complex.
                        </p>
                        <i className='icon-learn About__objectives__panel__icon'/>
                    </div>
                    <div className="About__objectives__panel About__objectives__panel--work">
                        <h2 className='About__objectives__panel__title'>Work</h2>
                        <p className='About__objectives__panel__body'>
                            Don't you think there's no work to be done. Exercises and assignments right
                            after theoretical explanations and illustrations.
                        </p>
                        <i className='icon-work About__objectives__panel__icon'/>
                    </div>
                    <div className="About__objectives__panel About__objectives__panel--grow">
                        <h2 className='About__objectives__panel__title'>Grow</h2>
                        <p className='About__objectives__panel__body'>
                            Grow step by step by applying the knowledge acquired in previous tutorials.
                            Step up the game, tutorial by tutorial.
                        </p>
                        <i className='icon-grow About__objectives__panel__icon'/>
                    </div>
                    <div className="About__objectives__panel About__objectives__panel--share">
                        <h2 className='About__objectives__panel__title'>Share</h2>
                        <p className='About__objectives__panel__body'>
                            Make sure to share the things you consider important, or simply
                            share something you liked while learning. Sharing is caring.
                        </p>
                        <i className='icon-share About__objectives__panel__icon'/>
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default Objectives;
