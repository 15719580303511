import './AboutPage.scss'
import React from 'react'
import Creator from "./Creator";
import Objectives from "./Objectives";
import Future from "./Future";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const AboutPage = () => (
    <Layout>
        <SEO title="About"/>
        <Creator/>
        <Objectives/>
        <Future/>
    </Layout>
);

export default AboutPage
